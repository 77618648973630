import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { getSrc } from 'gatsby-plugin-image'

const PostNavigation = ({ pageContext }) => {

	const { next, prev } = pageContext

	return (
        <NavigationPost>
			{prev &&
				<NavArticle
					className="prev-article"
					img_background={prev.frontmatter.hero ? getSrc( prev.frontmatter.hero) : (prev.frontmatter.imghero)} >
					<Link to={prev.frontmatter.slug} >
						<p className="post-scorso">Post Scorso</p>
						<h3>{prev.frontmatter.title}</h3>
					</Link>
				</NavArticle>
			}
			{next &&
				<NavArticle className="next-article"
					img_background={next.frontmatter.hero ? getSrc( next.frontmatter.hero) : (next.frontmatter.imghero)} >
					<Link to={next.frontmatter.slug} >
						<p className="prossimo-post">Prossimo Post</p>
						<h3>{next.frontmatter.title}</h3>
					</Link>
				</NavArticle>
			}
		</NavigationPost>
    );
}
export default PostNavigation


const NavigationPost = styled.nav`
	max-width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

		@media (max-width: 500px) {
			grid-template-columns: 1fr;
		}
`
const NavArticle = styled.article`
	min-height: 20vh;
	padding: 40px;
	> a > p { 
		color: peru;
		font-size: 1.2rem;
		font-weight: bold;
		margin-bottom: 0.5rem;
		}
	> a {color: #e1e1e1; text-decoration: none; }
	&.next-article {text-align: right;}

	/* BACKGROUND IMAGE INSIDE ARTICLE NAV */
	background-image:
		linear-gradient(rgba(77, 50, 23, 0.65),rgba(23, 28, 77, 0.65)),
		url(${props => props.img_background || '/immagini-bkgrnd/Liquid-Cheese.svg'});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

		@media (min-width: 1200px) { 
			min-height: 25vh;
			> a > h3 { font-size: 2.5vw; }
		}
		@media (min-width: 1470px) {
			min-height: 30vh;
			> a > h3 { font-size: 3vw; }
		}
`
import React, { useState, createRef } /* , {Component} */ from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo/seo";
// import Layout from './layout'
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import PostNavigation from "../components/postNavigation";

// import Transition from "../components/transition";
import Transition from "../components/transition-spring";
import styled from '@emotion/styled'

import { Spring } from "react-spring/renderprops";
import { FaComments } from "react-icons/fa";
import { ImagenCopertina } from "../components/springhero";
import BottoneCommenti from "../components/buttons/buttonComments";
import { isServer } from "../layouts/layoutDefault";
import BottoneShare from "../components/buttons/buttonShare";
import { getImage, getSrc } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

/* QUERYS:
    - Static Query può essere usata ovunque, ma non accetta variabili e non può usare "context"
    - Page Query può essere usata solo nelle pagine, ma possono accettare variabili usare context
*/
export const query = graphql`query PostQuery($slug: String!) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      title
      date
      giorno: date(formatString: "DD")
      mese: date(formatString: "MMMM", locale: "it_IT")
      anno: date(formatString: "YYYY")
      description
      slug
      hero {
				...FileFragment
			}
    }
  }
  mdx(frontmatter: {slug: {eq: $slug}}) {
    body
    frontmatter {
      title
      date
      giorno: date(formatString: "DD")
      mese: date(formatString: "MMMM", locale: "it_IT")
      anno: date(formatString: "YYYY")
      description
      slug
			hero {
				...FileFragment
			}
    }
  }
}

fragment FileFragment on File {
	childImageSharp {
		gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
	}
}
`; // questa è una PAGE QUERY perché vogliamo la query nella pagina , quindi deve essere una query creata come export e chiamarla query

/* STYLE */

const PostWrapper = styled.div`
  max-width: 39rem;
  /* margin: 1em 1.2rem auto; con la sidebar attiva */
	margin: 1em auto;
	.data-post {
		font-size: 0.7rem;
		font-weight: bold;
		margin-top: -1rem;
		margin-bottom: 1rem;
	}

	article a {
		transition: all ease-in-out 0.3s;
		&:hover,
		&:active,
		&:focus {
		  background-color: hsl(0, 100%, 94%);
		  text-decoration: none;
		}
		&:focus { outline: 0.2em dashed #be1212; }
		/* Dark version fallback */
		.dark &{ &:hover, &:active, &:focus { background-color: #443755; } }
	}
  @media screen and (max-width: 999px) {
    margin: 0 auto;
    padding: 2rem;
  }
	/* MOBILE */
		@media (max-width: 500px) {
			margin: 0 auto;
			padding: 0 1rem;
		}
`
const Main = styled.main`
	grid-template-columns: 1fr;
	max-width: 42rem;
`
const ButtonDisqusWrapper = styled.div`
	display: block;
	text-align: center;
	margin: 1em auto 2em;
	> button {
		margin-bottom: 1rem;
	}
`

const PostLayout = ({ data, location, pageContext }) => {

	const [comments, toggleComments] = useState(false);

	const isMdx = data.mdx !== null 
	const PostData = data.markdownRemark ?? data.mdx
	let disqusConfig = {
		url: `https://www.anonimoconiglio.com${PostData.frontmatter.slug}`,
		title: `${PostData.frontmatter.title}`
	}
	
	const posizioneCommenti = createRef()

	return (
        // <Layout location={location}>
        //</Layout>
        <>
            <Seo
                isBlogPost
                title={PostData.frontmatter.title}
                description={PostData.frontmatter.description}
                ogImg={PostData.frontmatter.hero ? getSrc( PostData.frontmatter.hero ) : PostData.frontmatter.imghero }
                slug={PostData.frontmatter.slug}
                fecha={PostData.frontmatter.date}
            />
            {PostData.frontmatter.hero !== null && (
                <header>
                        <Spring from={{ height: '0vh' }} to={{ height: isServer ? '36vh' : window.innerWidth <= 500 ? '30vh' : '36vh' }}>
                        {({ height }) => (
                            <div style={{ overflow: "hidden", height }}>
                                <ImagenCopertina sorgente={ getImage(PostData.frontmatter.hero)} />
                            </div>
                        )}
                    </Spring>
                </header>
            )}

            <Main>
            
            <Transition location={location}>
                <PostWrapper>
                    <h1>{PostData.frontmatter.title}</h1>
                    <div className="data-post">
                        <time dateTime={PostData.frontmatter.date}>{PostData.frontmatter.giorno} {PostData.frontmatter.mese} / {PostData.frontmatter.anno}</time>
                    </div>
										{ isMdx ? (
											<MDXRenderer>{ PostData.body }</MDXRenderer>
										) : (
											<article dangerouslySetInnerHTML={ { __html: PostData.html } } />
										)}
                    <br />
                        <BottoneShare url={`https://www.anonimoconiglio.com${PostData.frontmatter.slug}`} title={PostData.frontmatter.title} />
                        <BottoneCommenti posizioneCommenti={posizioneCommenti} />
                        <ButtonDisqusWrapper ref={posizioneCommenti}>
                        {comments ? (
                            <>
                                <button onClick={() => toggleComments(false)}>Chiudi i commenti <FaComments /></button>
                                <br />
                                <Disqus config={disqusConfig} />
                            </>
                        ) : (
                                <>
                                    <button onClick={() => toggleComments(true)}>Leggi tutti i commenti <FaComments /></button>
                                    <br />
                                    <CommentCount config={disqusConfig} placeholder={'...'} />
                                </>
                            )}
                    </ButtonDisqusWrapper>

                </PostWrapper>
            </Transition>

            </Main>

            <PostNavigation pageContext={pageContext} data={data} />
    </>
    );}
export default PostLayout

// Lo sto facendo con page query e una stateless function (altra info: https://www.gatsbyjs.org/docs/page-query/ ), però si può fare anche con una class, controllare gli appunti qui sotto.

/* In Alternativa:
CLASS FUNCTION: è valido pure questo ma bisogna attivare il component quando si importa react(vedi il codice commentato in alto) e poi bisogna togliere l'export sotto (perché qui facendo la funzione "class" lo stiamo già sportando).

export default class postLayout extends Component {
    const {markdownRemark} = this.props.data;
    render() {
        return(
            <Layout>
                <h1>{markdownRemark.frontmatter.title}</h1>
                <div  dangerouslySetInnerHTML={{
                __html: markdownRemark.html
            }}/>
            </Layout>
        )
    }
} */

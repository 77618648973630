import React from 'react'
import styled from '@emotion/styled'
import { FaComments } from 'react-icons/fa';
import { useSetButtonVisibleAt } from './customHookButton'

const BottoneCommenti = styled.button`
  display: block; /* Hidden by default */
	position: fixed;
	bottom: 30px;
	right: 30px;
  z-index: 98; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add a mouse pointer on hover */
  font-size: 45px; /* Increase font size */
	background-color: transparent;
	color: #383058;
	animation: fadeIn 1s;
`

const ButtonComments = ({ posizioneCommenti }) => {

	const { isVisible, goTo } = useSetButtonVisibleAt(900)

	return (
		isVisible ? (
			<BottoneCommenti onClick={() => goTo(posizioneCommenti)} className="BottoneCommenti">
				<FaComments />
			</BottoneCommenti>
		) : (
			null
		)
	)
}
export default ButtonComments
// eslint-disable-next-line
import React from "react";
import { Transition, config } from 'react-spring/renderprops'
import { isServer } from "../layouts/layoutDefault";

const TransitionSpring = ({children, location}) => (

	isServer ? (
		<div>{children}</div>
	) : (
		<Transition
			config = {config.gentle}
			reset
			unique
			keys = {location.pathname}
			from = {{ opacity: 0.2 }}
			enter = {{ opacity: 1 }}
			leave = {{ opacity: 0 }}
		>

			{() => style => (
				<div style={style}>
					{children}
				</div>
			)}

		</Transition >
	)
)
export default TransitionSpring
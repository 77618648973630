import React from 'react'
import styled from '@emotion/styled'

import { FaShareAlt } from 'react-icons/fa';
import { useSetButtonVisibleAt } from './customHookButton'

const BottoneTop = styled.button`
  display: block; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 160px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add a mouse pointer on hover */
  font-size: 45px; /* Increase font size */
	background-color: transparent;
	color: #383058;
	
	animation: fadeIn 1s;
`

const ButtonShare = ({ url, title }) => {
	
	const deviceIsMobile = typeof window !== 'undefined' && window.innerWidth <= 500

	const shareLink = () => {
		if (navigator.canShare && deviceIsMobile) {
			navigator.share({
				title: title,
				url: url
			}).then(() => console.log('Sharing successful'))
			.catch(err => console.log('Error on sharing: ',err))
		} else {
			navigator.clipboard.writeText(`${title} \nby @anonimoconiglio \n${url}`)
				.then(() => alert('Hai copiato il link nei tuoi appunti! \nIncollalo dove vuoi ;)'))
				.catch(error => alert(`Qualcosa è andato storto, per favore riporta l'errore al gestore del sito:`, error))
		}
	}

	const { isVisible } = useSetButtonVisibleAt(600)

	return (
		isVisible 
			? <BottoneTop onClick={shareLink} className="BottoneShare"> <FaShareAlt /> </BottoneTop> 
			: null
	)
}
export default ButtonShare